import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';


export const DoorbellPage = () => {
	let storedUsers: any[] = [];
	let storedUser: string = '';
	const navigate = useNavigate();

	const [users, setUsers] = useState<any[]>(storedUsers);
	const [currentUser, setUser] = useState<string>(storedUser);
	const [isButtonDisabled, setButtonDisabled] = useState(true);

	const getUsers = async () => {
		const response = await fetch(
			'https://dingdong-api.guglielmo.dev/api/users'
		);
		return response.json();
	}

	useEffect(() => {
		getUsers().then((response => {
			setUsers(response);
		}));
	}, []);

	const connectToPreJoin = (user: any) => {
		navigate({
			pathname: '/pre-join',
			search: '?mode=poc&iosToken=' + user.iostoken + '&androidToken=' + user.androidtoken + '&user=' + currentUser,
		});
	};

	return (
		<div className="prejoin">
			<main style={{textAlign: "center"}}>
				<h2>DingDongCode POC</h2>
				<hr />
				<div className="controlSection">
					<div className="label">Enter your nickname</div>
					<div>
						<input
							style={{width: "100%"}}
							type="text"
							name="user"
							value={currentUser}
							onChange={(e) => {
								setUser(e.target.value);
								if (e.target.value.length > 3) {
									setButtonDisabled(false);
								} else {
									setButtonDisabled(true);
								}
							}}
							autoFocus={true}
						/>
					</div>
				</div><br />
				<h2>My Doorbell</h2>
				<hr />
				{users.map((user, index) => (
					<p><button 
						className="_hRq5k" 
						onClick={connectToPreJoin.bind(null, user)} 
						key={index}
						disabled={isButtonDisabled}>
							Call {user.first_name} {user.last_name}
					</button></p>
				))}
			</main>
			<footer>
				&copy; Guglielmo
			</footer>
		</div>
	);
}