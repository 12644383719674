import { faBolt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { createLocalVideoTrack, LocalVideoTrack } from 'livekit-client';
import { AudioSelectButton, ControlButton, VideoSelectButton } from '@livekit/react-components';
import { VideoRenderer } from '@livekit/react-core';
import { ReactElement, useEffect, useState } from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import { useLocation, useNavigate } from 'react-router-dom';


export const PreJoinPage = () => {
	// initial state from query parameters
	const searchParams = new URLSearchParams(useLocation().search);
	const storedUrl = searchParams.get('url') ?? 'wss://dingdong.guglielmo.dev';
	const storedToken = searchParams.get('token') ?? '';
	const roomName = "POC-DINGDONGCODE";
	const mode = searchParams.get('mode') ?? 'standard';
	const iosToken = searchParams.get('iosToken') ?? "";
	const androidToken = searchParams.get('androidToken') ?? "";
	const user = searchParams.get('user') ?? '';


	// state to pass onto room
	const [url, setUrl] = useState(storedUrl);
	const [token, setToken] = useState<string>(storedToken);
	const [simulcast, setSimulcast] = useState(true);
	const [dynacast, setDynacast] = useState(true);
	const [adaptiveStream, setAdaptiveStream] = useState(true);
	const [videoEnabled, setVideoEnabled] = useState(false);
	const [audioEnabled, setAudioEnabled] = useState(true);
	// disable connect button unless validated
	const [connectDisabled, setConnectDisabled] = useState(true);
	const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
	const [audioDevice, setAudioDevice] = useState<MediaDeviceInfo>();
	const [videoDevice, setVideoDevice] = useState<MediaDeviceInfo>();
	const navigate = useNavigate();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const tokenFromUser = async (roomName: string) => {
		const response = await fetch(
			`https://dingdong-api.guglielmo.dev/api/token?partecipant=${user}&roomName=${roomName}`
			//`http://localhost:3000/api/token?partecipant=${user}&roomName=${roomName}`
		);
		return response.json();
	}

	useEffect(() => {
		if (user) {
			tokenFromUser(roomName).then(response => {
				setToken(response.token);
			})
		}
	}, [tokenFromUser, user]);

	useEffect(() => {
		if (token && url) {
			setConnectDisabled(false);
		} else {
			setConnectDisabled(true);
		}
	}, [token, url]);

	const toggleVideo = async () => {
		if (videoTrack) {
			videoTrack.stop();
			setVideoEnabled(false);
			setVideoTrack(undefined);
		} else {
			const track = await createLocalVideoTrack({
				deviceId: videoDevice?.deviceId,
			});
			setVideoEnabled(true);
			setVideoTrack(track);
		}
	};

	useEffect(() => {
		// enable video by default
		createLocalVideoTrack({
			deviceId: videoDevice?.deviceId,
		}).then((track) => {
			setVideoEnabled(true);
			setVideoTrack(track);
		});
	}, [videoDevice]);

	const toggleAudio = () => {
		if (audioEnabled) {
			setAudioEnabled(false);
		} else {
			setAudioEnabled(true);
		}
	};

	const selectVideoDevice = (device: MediaDeviceInfo) => {
		setVideoDevice(device);
		if (videoTrack) {
			if (videoTrack.mediaStreamTrack.getSettings().deviceId === device.deviceId) {
				return;
			}
			// stop video
			videoTrack.stop();
		}
	};

	const connectToRoom = async () => {
		if (videoTrack) {
			videoTrack.stop();
		}

		if (
			window.location.protocol === 'https:' &&
			url.startsWith('ws://') &&
			!url.startsWith('ws://localhost')
		) {
			alert('Unable to connect to insecure websocket from https');
			return;
		}

		const params: { [key: string]: string } = {
			url,
			token,
			videoEnabled: videoEnabled ? '1' : '0',
			audioEnabled: audioEnabled ? '1' : '0',
			simulcast: simulcast ? '1' : '0',
			dynacast: dynacast ? '1' : '0',
			adaptiveStream: adaptiveStream ? '1' : '0',
		};
		if (audioDevice) {
			params.audioDeviceId = audioDevice.deviceId;
		}
		if (videoDevice) {
			params.videoDeviceId = videoDevice.deviceId;
		} else if (videoTrack) {
			// pass along current device id to ensure camera device match
			const deviceId = await videoTrack.getDeviceId();
			if (deviceId) {
				params.videoDeviceId = deviceId;
			}
		}

		fetch(
			`https://dingdong-api.guglielmo.dev/api/notify?partecipant=federico.bertoli&roomName=${roomName}&iosToken=${iosToken}&androidToken=${androidToken}`
			//`http://localhost:3000/api/notify?partecipant=federico.bertoli&roomName=${roomName}&iosToken=${iosToken}&androidToken=${androidToken}`
		)
			.then((response) => {
				return response.json();
			})
			.then((response) => {
				console.log(response);

				navigate({
					pathname: '/room',
					search: '?' + new URLSearchParams(params).toString(),
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	let videoElement: ReactElement;
	if (videoTrack) {
		videoElement = <VideoRenderer track={videoTrack} isLocal={true} />;
	} else {
		videoElement = <div className="placeholder" />;
	}

	const goBack = () => {
		navigate({
			pathname: '/',
		});
	}

	return (
		<div className="prejoin">
			<main>
				<h2>DingDongCode POC</h2>
				<hr />
				<div className="entrySection">
					<div style={{ display: mode === 'poc' ? 'none' : 'block' }}>
						<div className="label">LiveKit URL</div>
						<div>
							<input type="text" name="url" value={url} onChange={(e) => setUrl(e.target.value)} />
						</div>
					</div>
					<div style={{ display: mode === 'poc' ? 'none' : 'block' }}>
						<div className="label">Token</div>
						<div>
							<input
								type="text"
								name="token"
								value={token}
								onChange={(e) => setToken(e.target.value)}
								autoFocus={true}
							/>
						</div>
					</div>
					<div style={{ display: mode === 'poc' ? 'none' : 'block' }} className="options">
						<div>
							<input
								id="simulcast-option"
								type="checkbox"
								name="simulcast"
								checked={simulcast}
								onChange={(e) => setSimulcast(e.target.checked)}
							/>
							<label htmlFor="simulcast-option">Simulcast</label>
						</div>
						<div>
							<input
								id="dynacast-option"
								type="checkbox"
								name="dynacast"
								checked={dynacast}
								onChange={(e) => setDynacast(e.target.checked)}
							/>
							<label htmlFor="dynacast-option">Dynacast</label>
						</div>
						<div>
							<input
								id="adaptivestream-option"
								type="checkbox"
								name="adaptiveStream"
								checked={adaptiveStream}
								onChange={(e) => setAdaptiveStream(e.target.checked)}
							/>
							<label htmlFor="adaptivestream-option">Adaptive Stream</label>
						</div>
					</div>
				</div>

				<div className="videoSection">
					<AspectRatio ratio={16 / 9}>{videoElement}</AspectRatio>
				</div>

				<div className="controlSection">
					<div>
						<ControlButton
							label="Back"
							icon={faChevronLeft}
							onClick={goBack}
						/>
						<AudioSelectButton
							isMuted={!audioEnabled}
							onClick={toggleAudio}
							onSourceSelected={setAudioDevice}
						/>
						<VideoSelectButton
							isEnabled={videoTrack !== undefined}
							onClick={toggleVideo}
							onSourceSelected={selectVideoDevice}
						/>
					</div>
					<div className="right">
						<ControlButton
							label="Connect"
							disabled={connectDisabled}
							icon={faBolt}
							onClick={connectToRoom}
						/>
					</div>
				</div>
			</main>
			<footer>
				&copy; Guglielmo
			</footer>
		</div>
	);
};
